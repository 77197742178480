import React from "react";
import Home from './compontents/Home'
import NavBar from './compontents/NavBar'
import About from './compontents/About'
import Project from './compontents/Project'
import Skill from './compontents/Skill'
import Experience from './compontents/Experience'
import Contact from './compontents/Contact'
import SocialLinks from './compontents/SocialLinks'


function App() {
  return (
  <div>
   <NavBar />
   <Home />
   <About/>
   <Skill/>
   <Project/>
   <Experience/>
   <Contact/>
   <SocialLinks/>
  </div>
  );
}

export default App;
