import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'

const SocialLinks = () => {

  return (
    <div className='hidden lg:flex flex-col top-[35%] left-0 fixed'>
        <ul>
        <li  className='flex justify-between items-center w-40 h-14 px-4 hover:ml-[-10px] ml-[-100px] hover:rounded-md duration-300 bg-gray-500 rounded-tr-md'>
            <a href='https://www.linkedin.com/in/mohammed-fareestha-61aa17204/' className='flex justify-between items-center w-full text-white' target="_blank" >
                <>Linkedin <FaLinkedin size={30}/></>
            </a>
       </li>

        <li  className='flex justify-between items-center w-40 h-14 px-4 hover:ml-[-10px] ml-[-100px] hover:rounded-md duration-300 bg-gray-500'>
            <a href='https://github.com/Mohammed-Fareestha' className='flex justify-between items-center w-full text-white' target="_blank">
                <>GitHub <FaGithub size={30}/></>
            </a>
       </li>
       <li  className='flex justify-between items-center w-40 h-14 px-4 hover:ml-[-10px] ml-[-100px] hover:rounded-md duration-300 bg-gray-500 rounded-br-md'>
            <a href='mailto:mohammedfareestha@gmail.com' className='flex justify-between items-center w-full text-white' target="_blank">
                <>Resume <BsFillPersonLinesFill size={30}/></>
            </a>
       </li> 
        <li  className='flex justify-between items-center w-40 h-14 px-4 hover:ml-[-10px] ml-[-100px] hover:rounded-md duration-300 bg-gray-500 rounded-br-md'>
            <a href='mailto:mohammedfareestha@gmail.com' className='flex justify-between items-center w-full text-white' target="_blank">
                <>Gmail <HiOutlineMail size={30}/></>
            </a>
       </li>
        </ul>
    </div>
  )
}

export default SocialLinks



// const links= [
//     {
//         id:1,
//         child:(
//             <>
//             Linkedin <FaLinkedin size={30}/>
//             </>
//         ),
//         href:'https://github.com/Mohammed-Fareestha',
//         style:'rounded-tr-md',
//     },
//     {
//         id:2,
//         child:(
//             <>
//             GitHub <FaGithub size={30}/>
//             </>
//         ),
//         href:'https://github.com/Mohammed-Fareestha',
//     },
//     {
//         id:3,
//         child:(
//             <>
//             Gmail <HiOutlineMail size={30}/>
//             </>
//         ),
//         href:'mailto:mohammedfareestha@gmail.com',
        
//     },
//     {
//         id:4,
//         child:(
//             <>
//             Resume <BsFillPersonLinesFill size={30}/>
//             </>
//         ),
//         href:'/resume.pdf',
//         style:'rounded-br-md',
//         download:true,
//     },
// ]


// {links.map((id, child, href, style, download)=>(
//     <li key={id}  className={'flex justify-between items-center w-40 h-14 px-4 hover:ml-[-10px] ml-[-100px] hover:rounded-md duration-300 bg-gray-500' + " " + style}>

//     <a href={href} className='flex justify-between items-center w-full text-white'>
//         {child} </a>
        
   
//     </li>
// ))}