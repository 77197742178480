import React from 'react'
import ecuto from "../assets/eucto.jpeg"


const Experience = () => {
  return (
    <div
      name="experience"
      className="bg-gradient-to-b from-black to-gray-800 text-white  w-full max-h-full pb-44 pt-36">
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-gray-500">
          Experience</p>
          <p className="py-6">Check out my work-experirnce right here</p>
        </div>

        <div class="flex justify-center">
     {/* <div class="flex flex-col md:flex-row md:max-w-xl rounded-lg bg-white shadow-lg">
        <img class=" w-full h-96 md:h-auto object-cover md:w-48 rounded-t-lg md:rounded-none md:rounded-l-lg" src={ecuto} alt="Ecuto" />
        <div class="p-6 flex flex-col justify-start">
        <h5 class="text-gray-900 text-xl font-medium mb-2">Eucto</h5>
          <h5 class="text-gray-900 text-xl font-medium mb-2">Software Developer</h5>
        <p class="text-gray-700 text-base mb-4">
        This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
        </p>
     </div>
     </div> */}
     <div style={{borderRadius:'10px', border:'1px solid #000', background:'#ffff', width:'100%'}}>
       <div >
       <div class="grid grid-cols-3 gap-4">
            <div class="col-span-1 bg-white p-4 shadow rounded text-black">
                <img src={ecuto} alt="Ecuto" style={{width: '50%'}} />
                <h1 style={{fontWeight: '800'}}>About</h1>
                <h4>Eucto in short is Business Transformation - YOUR Business Transformation, From Human Resources, to Digital Marketing, to Finance, to IT Sector and Counseling to Leadership trainings</h4>
            </div>

            <div class="col-span-2 bg-white p-4 shadow rounded text-black">

                <div class="grid grid-cols-2 gap-4">
                  <div class="col-span-1 bg-white p-4  text-black">
                    <li style={{fontWeight:'700',fontFamily:'sans-serif'}}>Software Developer</li>
                    <p style={{fontWeight:'lighter', marginLeft:'22px'}}>Eucto Technology</p>
                  </div>
                  <div class="col-span-1 bg-white p-4 text-end text-black">
                    <p style={{fontFamily:'math'}}> January 2023 -- present</p>
                  </div>
                </div>
                <div>
                  <h1 style={{ marginLeft: '40px'}}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae quidem corporis sunt accusantium, odio soluta rerum cum officia amet unde quod id quibusdam, in labore! Obcaecati voluptates unde deserunt totam!</h1>
                </div>

                <div class="grid grid-cols-2 gap-4">
                  <div class="col-span-1 bg-white p-4  text-black">
                    <li style={{fontWeight:'700',fontFamily:'sans-serif'}}>Front-End Engineer</li>
                    <p style={{fontWeight:'lighter', marginLeft:'22px'}}>Eucto Technology</p>
                  </div>
                  <div class="col-span-1 bg-white p-4 text-end text-black">
                    <p style={{fontFamily:'math'}}></p>
                  </div>
                </div>
                <div>
                  <h1 style={{ marginLeft: '40px'}}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae quidem corporis sunt accusantium, odio soluta rerum cum officia amet unde quod id quibusdam, in labore! Obcaecati voluptates unde deserunt totam!</h1>
                </div>
            </div>
            </div>
       </div>
     </div>
       </div>
   </div>
    </div>
  );
};

export default Experience

