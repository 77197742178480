import React from "react";
import projectone from "../assets/project/projectone.png"



export default function Projects() {
  return (
    <div name="projects" className="bg-gradient-to-b from-gray-800 to-black text-white  w-full max-h-full pt-56">
    <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
      <div className="pb-8">
        <p className="text-4xl font-bold inline border-b-4 border-gray-500">
          Project
        </p>
        <p className="py-6">Check out some of my work right here</p>
      </div>


   



      <div class="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
{/* Card-1 */}
      <div class="max-w-sm rounded overflow-hidden shadow-lg">
  <img class="w-full" src={projectone} alt="Sunset in the mountains"/>
  <div class="px-6 py-4">
    <div class="font-bold text-xl mb-2">E-Commerce website for Gadgets</div>
    <p class="text-blue-200 text-base">
    A fully functioning and responsive E-commerce Website for Gadgets, building with HTML/CSS (Bootstrap) and javaScript 
    </p>
  </div>
  <div class="px-6 pt-4 pb-2">
  <button class="bg-cyan-600 hover:bg-cyan-900 py-3 px-4 m-4 duration-200 text-white font-bold rounded-full"><a href="https://github.com/Mohammed-Fareestha/E-Commerce-website-for-Gadgets">Code</a></button>
  <button class="bg-cyan-600 hover:bg-cyan-900 py-3 px-4 m-4 duration-200 text-white font-bold  rounded-full"><a href="https://aesthetic-manatee-058f8c.netlify.app/">Demo</a></button>
  </div>
</div> 

{/* Card-2 */}

      <div class="max-w-sm rounded overflow-hidden shadow-lg">
  <img class="w-full" src={projectone} alt="Sunset in the mountains"/>
  <div class="px-6 py-4">
    <div class="font-bold text-xl mb-2">E-Commerce website for Gadgets</div>
    <p class="text-blue-200 text-base">
    A fully functioning and responsive E-commerce Website for Gadgets, building with HTML/CSS (Bootstrap) and javaScript 
    </p>
  </div>
  <div class="px-6 pt-4 pb-2">
  <button class="bg-cyan-600 hover:bg-cyan-900 py-3 px-4 m-4 duration-200 text-white font-bold rounded-full"><a href="https://github.com/Mohammed-Fareestha/E-Commerce-website-for-Gadgets">Code</a></button>
  <button class="bg-cyan-600 hover:bg-cyan-900 py-3 px-4 m-4 duration-200 text-white font-bold  rounded-full"><a href="https://aesthetic-manatee-058f8c.netlify.app/">Demo</a></button>
  </div>
</div> 

{/* Card-3 */}

    
<div class="max-w-sm rounded overflow-hidden shadow-lg">
  <img class="w-full" src={projectone} alt="Sunset in the mountains"/>
  <div class="px-6 py-4">
    <div class="font-bold text-xl mb-2">E-Commerce website for Gadgets</div>
    <p class="text-blue-200 text-base">
    A fully functioning and responsive E-commerce Website for Gadgets, building with HTML/CSS (Bootstrap) and javaScript 
    </p>
  </div>
  <div class="px-6 pt-4 pb-2">
  <button class="bg-cyan-600 hover:bg-cyan-900 py-3 px-4 m-4 duration-200 text-white font-bold rounded-full"><a href="https://github.com/Mohammed-Fareestha/E-Commerce-website-for-Gadgets">Code</a></button>
  <button class="bg-cyan-600 hover:bg-cyan-900 py-3 px-4 m-4 duration-200 text-white font-bold  rounded-full"><a href="https://aesthetic-manatee-058f8c.netlify.app/">Demo</a></button>
  </div>
</div> 

</div>
    </div>
  </div>
  );
}













// import React from "react";
// import projectone from "../assets/project/projectone.png"
// import projectsix from "../assets/project/projectsix.png"


// const Project = () => {
    
// const portfolios = [
//     {
//       id: 1,
//       src: projectone,
//       href:'https://aesthetic-manatee-058f8c.netlify.app/',
//       code:'https://github.com/Mohammed-Fareestha/E-Commerce-website-for-Gadgets',
//     },
//     {
//       id: 2,
//       src: projectone,
//     },
//     {
//       id: 3,
//       src: projectone,
//     },
//     {
//       id: 4,
//       src: projectone,
//     },
//     {
//       id: 5,
//       src: projectsix,
//       href:'https://leafy-mermaid-d92379.netlify.app',
//       code:'https://github.com/Mohammed-Fareestha/openBreApi/tree/main/Open%20Brewery%20API',
//     },
//     {
//       id: 6,
//       src: projectone,
//     },
//   ];
  
//   return (
    // <div
    //   name="projects"
    //   className="bg-gradient-to-b from-gray-800 to-black text-white  w-full max-h-full pt-56">
    //   <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
    //     <div className="pb-8">
    //       <p className="text-4xl font-bold inline border-b-4 border-gray-500">
    //         Project
    //       </p>
    //       <p className="py-6">Check out some of my work right here</p>
    //     </div>
  
    //     <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
    //       {portfolios.map(({ id, src ,href,code }) => (
    //         <div key={id} className="shadow-md shadow-gray-600 rounded-lg">
    //           <img
    //             src={src}
    //             alt=""
    //             className="rounded-md duration-200 hover:scale-105"
    //           />
    //           <div className="flex items-center justify-center">
    //             <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">
    //               <a href={href}>Demo</a>
    //             </button>
                // <button className="w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105">
                // <a href={code}>Code</a>
                // </button>
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </div>
//   );
// }
// export default Project;






