import React from 'react'
import Heroimg from '../assets/herom.png'
import { Link } from "react-scroll";
import {BsArrowRightCircleFill} from "react-icons/bs";

const Home = () => {
  return (
    <div name="home" className='h-screen w-full bg-gradient-to-b from-black to-gray-800 text-white via-black'>
       <div className='max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row '>
        <div className='flex flex-col justify-center h-full'>

          <h1 className='text-4xl sm:text-7x1 font-bold text-white'>I'm a Full Stack Developer</h1>

          <p className='text-gray-500 py-4 max-w-md'> I am currently into MERN Stack and Looking forward to kick-start my career in an organization that gives me chance to prove my abilities and helps to develop my technical, interpersonal skills and achieving the vision of the organization</p>
          <div>
             <Link
              to="projects"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer">
              Projects
              <span className="group-hover:rotate-90 duration-300">
                <BsArrowRightCircleFill size={20} className="ml-1" />
              </span>
            </Link>

          </div>
        </div>
        <div>
            <img src={Heroimg} alt='mohammed' className='rounded-lg  pl-5 mx-auto h-96 w-4/5 md:w-full'/>
        </div>
       </div>
    </div>
  )
}

export default Home


