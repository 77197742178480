import React from 'react'

import html from "../assets/html.png"
import css from "../assets/css.png"
import bootstrap from "../assets/bootstrap.png"
import github from "../assets/github.png"
import javascript from "../assets/javascript.png"
import mongoDb from "../assets/mongoDb.png"
import nextjs from "../assets/nextjs.png"
import python from "../assets/python.png"
import react from "../assets/react.png"
import tailwind from "../assets/tailwind.png"
import node from "../assets/node.png"
import wordpress from "../assets/wordpress.png"


const Skill = () => {
  const techs = [
    {
      id: 1,
      src: javascript,
      title: "JavaScript",
      style: "shadow-orange-500",
    },
    {
      id: 2,
      src: react,
      title: "React",
      style: "shadow-blue-500",
    },
    {
      id: 3,
      src: nextjs,
      title: "Next JS",
      style: "shadow-yellow-500",
    },
    {
      id: 4,
      src: html,
      title: "HTML",
      style: "shadow-blue-600",
    },
    {
      id: 5,
      src: css,
      title: "CSS",
      style: "shadow-sky-400",
    },
    {
      id: 6,
      src: bootstrap,
      title: "Bootstrap",
      style: "shadow-sky-400",
    },
    {
      id: 7,
      src: node,
      title: "NodeJS",
      style: "shadow-white",
    },
    {
      id: 8,
      src: python,
      title: "Python",
      style: "shadow-gray-400",
    },
    {
      id: 9,
      src: tailwind,
      title: "Tailindcss",
      style: "shadow-gray-400",
    },
    {
      id: 10,
      src: mongoDb,
      title: "MongoDB",
      style: "shadow-gray-400",
    },
    {
      id: 11,
      src: wordpress,
      title: "Wordpress",
      style: "shadow-gray-400",
    },
    {
      id: 12,
      src: github,
      title: "GitHub",
      style: "shadow-gray-400",
    },
  ];

  return (
    <div
      name="Skill"
      className="bg-gradient-to-b from-black to-gray-800 w-full py-96   text-white md:h-screen">
    
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4  mt-96  border-gray-500 p-1 inline">
            Skills
          </p>
          <p className="py-6">These are the technologies I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
          {techs.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
            >
              <img src={src} alt="" className="w-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skill;










// import React from 'react'
// import html from "../assets/project/html.png"
// import css from "../assets/project/css.png"
// import bootstrap from "../assets/project/bootstrap.png"
// import github from "../assets/project/github.png"
// import javascript from "../assets/project/javascript.png"
// import mongoDb from "../assets/project/mongoDb.png"
// import nextjs from "../assets/project/nextjs.png"
// import python from "../assets/project/python.png"
// import react from "../assets/project/react.png"
// import tailwind from "../assets/project/tailwind.png"
// import vsimg from "../assets/project/vsimg.png"

// const Skill = () => {
//   return (
//     <div>Skill</div>
//   )
// }

// export default Skill
