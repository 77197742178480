import React from 'react'

const About = () => {
  return (
    <div name='about-me' className='w-full pb-10 h-screen bg-gradient-to-b from-gray-800 to-black text-white'>
        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
            <div className="pb-8">
                <p  className="text-4xl font-bold inline border-b-4 border-gray-500">About</p>
            </div>
            <p className="text-xl mt-15">
              Hello all, I'm Mohammed Fareestha, Experienced ReactJS Developer with a proven track record of success. I bring a wealth of expertise in API Integration, Node.js, MySQL, MongoDB, and UI/UX design using Figma. I hold a B.Tech in Electronics and Communication Engineering from the SRM Institute of Science and Technology. My goal is to drive immediate value to your team and contribute significantly to the growth and development of your company, while also advancing my own career. I'm actively seeking new opportunities where I can apply my skills and passion for web development. Let's connect and explore how we can collaborate for mutual success
              </p>
            <br/>
            <p className="text-xl">I am currently looking into MERN Stack, and I anticipate kicking off my career in an organization that allows me to prove my abilities and helps in developing my technical and interpersonal skills and in achieving the vision of the organization</p>
        </div>
    </div>
  )
}

export default About
